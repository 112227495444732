

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-card-image .card.image-full:before {
    border-radius: 0.5rem;
    opacity:0.45;
}

.map-container {
    height: 50vh;
    width:50vw;
    }
     
.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #fff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 12px;
border-radius: 4px;
}
.hero {
    background: url('../public/night_re1.jpg') no-repeat center center/cover;
    height:100vh;
    position: relative;
    color: #fff;

}

.marker {
    background-image: url('mapbox-icon.png');
    background-size: cover;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
  }

  .hero2 {
    min-height: 100vh;
    background-image: url('../public/night_re1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.project-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
    background-color: rgba(0, 0, 0, 0.6);  /* Darkened container */
    padding: 20px;
    border-radius: 10px;
    width: 1000px;
    height: 400px;
}

.project-image {
    width: auto;  /* Allows image to resize based on aspect ratio */
    max-width: 400px;  /* Constrain maximum width */
    max-height: 400px;  /* Constrain maximum height */
    display: block;  /* Block level element ensures proper centering */
    margin: 0 auto;  /* Centers image in its container */
}

.project-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    font-size: 1rem;
    flex-grow: 1;
}

.project-title, .project-description {
    color: white;
    font-size: 2rem;
}
